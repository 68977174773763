.SummarySection {
  background-image: url("../../asset/images/Summary/summary4.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 450px;
  width: 100%;
}

.summaryMargin {
  margin-top: 5rem;
}

.summaryOverlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 450px;
}

.summaryAlign {
  margin-top: 2rem;
  color: #fff;
  width: 30%;
  margin: auto;
  border: 1px solid #fff;
}

.summaryTitle {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: 800;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.countNumber {
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 25px;
  font-weight: 600;
}

.countSection {
  margin-top: 18%;
}

.summaryCardTitle {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  margin: 1rem 0;
  /* color: #3d405b; */
  color: #2E4F4F;
}

.summaryCardText {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  color: #999999;
}

.summaryIcon {
  /* color: #17a2b8; */
  color:  #179b87;
  margin-right: 5px;
}

.summaryCard {
  margin-top: 20%;
  padding: 10px;
  border-radius: 5px;
}

.chartsCountUp {
  font-size: 32px;
  color: #fff;
}

@media only screen and (max-width: 600px) and (min-width: 400px)  {

}

@media (min-width: 768px) {
  .summaryCard{
    margin-top: 6rem;
  }
}

@media (max-width: 1000px) {
  .summaryTitle {
    font-size: 16px;
    font-weight: 800;
  }
  .chartsCountUp {
    font-size: 25px;
  }
  .summaryCardText {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .chartsCountUp {
    margin-top: 5rem;
  }
}

@media (max-width: 768px) {
  .SummarySection {
    min-height: 600px;
  }
  .summaryOverlay {
    height: 600px;
  }
  .chartsCountUp {
    font-size: 22px;
  }
  .summaryTitle {
    font-size: 18px;
  }
  .summaryCardText {
    font-size: 11px;
  }
  .chartsCountUp {
    margin-top: 1rem;
  }
  .summaryCard{
    margin-top: 2rem;
  }
}


@media (max-width: 492px) {
  .SummarySection {
    min-height: 600px;
  }
  .summaryOverlay {
    height: 600px;
  }
  .summaryTitle {
    font-size: 13px;
    font-weight: 600;
  }
  .chartsCountUp {
    font-size: 18px;
  }
  .summaryCardTitle {
    font-size: 15px;
  }
}

@media (max-width: 220px) {
  .SummarySection {
    min-height: 750px;
  }
  .summaryOverlay {
    height: 750px;
  }
}
