/* .upvcTable{
background-color: red !important;
} */

.upvcModalTitle{
 font-family: "Montserrat", sans-serif;
 font-size: 18px;
 text-transform: uppercase;
 font-weight: 600;
 margin-top: 10px;
 color: #273c75;
}

.upvcTable{
  border-radius: 10px !important;
}

.upvcTableTr{
    color:  #273c75;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}

.upvcTableBody{
    color: #000;
    font-size: 13px;
    font-family: 'Poppins', sans-serif; 
}

.upvcBtn{
    /* background-color: #f2f2f2; */
    background-color: #2980B9;
    font-size: 11px;
    padding: 5px;
    color: #fff;
    font-family: "Barlow",sans-serif;
    font-weight: 500;
    border: 1px solid #f2f2f2;
    width: 120px;
    height: 35px;
    margin: 1rem 0;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .upvcBtn:hover, .upvcBtn:focus {
    background-color:  #2573a7;
    color: #fff; 
    border: 1px solid #f2f2f2;
  }

 /*  table */

.upvcTableTr th,
.upvcTableTr td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-responsive {
  overflow-x: auto;
}

@media (max-width: 1000px) {
  .upvcTableBody {
    font-size: 11px;
  }
}

@media (max-width: 600px) {
  .upvcTableBody {
    font-size: 9px;
  }
}

.upvcHeader{
  font-size: 15px;
  font-weight: 700;
}

.tableSize{
  background-color: #8491C9;
  width: 100%;
  padding: 0;
  margin: 0;
}

.tableCode{
  background-color: #BCBCBC;
  /* width: 80px; */
}

.upvcTable{
  width: 700px;
}

.tableThickness{
background-color: #C4CAE4;
}

@media (max-width: 767px) {
  .upvcTable {
    width: 500px;
  }
}

@media (max-width: 550px) {
  .upvcTable {
    width: 450px;
  }  
.upvcHeader{
  font-size: 12px;
  font-weight: 600;
}
}

@media (max-width: 500px) {
  .upvcTable {
    width: 400px;
  }
}

@media (max-width: 450px) {
  .upvcTable {
    width: 300px;
  }
  .upvcHeader{
    font-size: 10px;
    font-weight: 600;
  }
}

@media (max-width: 350px) {
  .upvcTable {
    width: 250px;
  }
}
