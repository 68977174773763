
.experience{
    background-color: #17a2b8;
    font-size: 16px;
    padding: 20px;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

.experience::before {
    content: "\201C"; 
    font-size: 2em;
    margin-right: 0.25em;
  }

  .carrerNav{
    background: #fff;    
  }

  .careerHeader{
    color: #17a2b8 ;
    font-size: 32px;
    font-weight: 600;
  }

  .careerText{
    font-family: "Raleway",sans-serif;
    text-align: justify;
    color: #999999;
    letter-spacing: 1px;
    font-size: 20px;
    margin-bottom: 3rem;
  }

  .currentOpening{
    color: #000 ;
    font-size: 32px;
    font-weight: bold;
  }

  .jobBox{
    width: 400px;
    height: 150px;
    border: 1px solid #b3b3b3;
    padding: 20px;
    border-radius: 20px;
  }

  .jobHeader{
    font-size: 24px;
    font-weight: bold; 
  }
  
  .jobLeft{
    margin-bottom: 2rem;
  }

  .jobBox2{
    margin-bottom: 1rem; 
    width: 230px;
    height: 60px;
    border: 1px solid #b3b3b3;
    border-radius: 6px; 
    cursor: pointer;   
  }

  .jobHeader2{
    font-size: 15px;
    font-weight: bold; 
    padding: 1rem 0 1rem 10px;
  }

  .jobApplyNow{
    font-size: 20px;
    color: red;
    font-family: monospace;
    cursor: pointer;
  }

  .roleHeader{
    font-family: "Poppins", sans-serif;
    font-size: 20px; 
    color: #666666; 
    font-weight: 500;
  }

  .t_logo{
    width: 200px;
  }

  .jobApplyNow:hover{
    text-decoration: underline;
  }

  .careerMenu{
    color: #000;
    font-size: 20px;    
  }

  .jobLeft{
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    border-radius: 10px;
  }

    .jobLeftHeader {
      font-family: "Poppins", sans-serif;
      font-size: 20px;
      font-weight: 500;
      text-transform: uppercase; 
      color: #666666; 
      margin-top: 10px;
    }

    .documentAdd{
      background: #00b894;
      height: 100vh;
      /* padding: 3rem 5rem 3rem 5rem; */
    }

    .recentJobs{
      background: #fff;
      border-radius: 10px;
      padding: 3rem;
    }

    .gridLeft{
      padding: 2rem;
    }

    .job_button{
      font-size: 16px;
      background-color: #e74c3c !important; 
      color: #fff !important;
    }
   
    .jobDetailsPara{
      font-size: 18px;
    }

    @media (max-width:1500px){
      .jobBox2{        
        width: 200px;                 
      }
      .jobHeader2{
        font-size: 13px;
      }
      .recentJobs{
        padding: 2rem;
      } 
    }


    @media (max-width:1400px){
    .jobBox{
      width: 350px;     
      padding: 20px;      
    }
    .jobHeader{
      font-size: 20px;      
    } 
    .gridLeft{
      padding: 1rem;
    }
  }

  @media (max-width:1200px){
    .jobBox{
      width: 300px;     
      padding: 20px;      
    }
    .jobHeader{
      font-size: 20px;      
    } 
    .gridLeft{
      padding: 3rem;
    }
  }

  @media (max-width:1000px){
    .jobBox{
      margin-bottom: 2rem; 
    }
  }


  @media (max-width:768px){
    .careerHeader{     
      font-size: 22px;
    }
    .experience{
      font-size: 16px;
    }
    .careerText{
      font-size: 18px;
    }
    .currentOpening{
      font-size: 30px;      
    }   
    .jobLeftHeader {
      font-size: 16px;
    }
    .t_logo{
      width: 150px;
    }
    .jobDetailsPara{
      font-size: 16px;
    }
  }
  
  @media (max-width:480px){
    .careerHeader{    
      font-size: 18px;
    }
    .careerText{
      font-size: 16px; 
    } 
    .experience{
      font-size: 14px;
    }
    .currentOpening{
      font-size: 23px;       
    }
    .t_logo{
      width: 100px;
    }
    .roleHeader{      
      font-size: 14px;       
    }
    .jobLeft{
      padding: 1rem; 
    }
    .jobLeftHeader {      
      font-size: 14px;           
    }
    .jobBox{
      width: 250px;          
    }
    .jobHeader{
      font-size: 18px; 
    }
    .job_button{
      font-size: 13px;
    }
    .careerMenu{
      font-size: 16px;    
    }
    .jobDetailsPara{
      font-size: 14px;
    }
  }
