/* .chairmanMsg {
  background: linear-gradient(45deg, red, green);
} */

.chairmanHeading {
    color: black;
  }
  
  .chairmanImg img {
    width: 100%;
    height: 400px;
  }
  
  .chairmanText {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    text-align: justify;
    color: #000;
  }
  
  .ChairmanTitle{
    font-size: 15px;
    color: #2980b9;
    font-weight: 600 !important;
  }

  .msgMargin{
    margin-top: 5rem;
  }
 
  @media (max-width: 990px) {
    .chairmanText {
      font-size: 12px;
    }

    .chairmanImg img {
      margin-top: 3rem !important;
    }

  }

  @media (max-width:768px){
    .msgMargin{
      margin-top: 0;
    }
  }


  @media (max-width: 350px) {  
    .chairmanImg img {
     height: 280px;
     margin-top: 2rem !important;
    }

  }


  /* @media (max-width: 768PX) {
    .chairmanText{
      margin-top: 2rem;
    }
  } */
  