.profile-area {
  padding: 12px 0;
}

.managementCard {
  /* box-shadow: 0 0 30px rgba(0, 0, 0, 0.5); */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 15px;
  height: 440px;
  margin-bottom: 15px;
}

.img1 img {
  height: 170px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  width: 100%;
}

.img2 img {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 7px solid #fff;
  margin-top: -80px;
  background-color: #d9d9d9;
}

.managementCard:hover .img2 img {
  /* border-color: darkcyan; */
  border-color: #1dc6ad;
  transition: 1.5s;
}

.main-text {
  padding: 10px 0 10px 0;
  text-align: center;
}

.main-text h2 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  /* margin: 0 0 20px; */
  color: #2E4F4F;
  font-family: 'Montserrat', sans-serif;
}

.main-text p {
  font-size: 16px;
}

.main-text-p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #2E4F4F;
}
.socials {
  text-align: center;
  /* padding-bottom: 20px; */
  color: #b2bec3;
  /* margin-bottom: 20px; */
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.socials svg {
  font-size: 25px;
  transition: all 0.3s ease-in-out;
}

.socials > *:nth-child(1){
  font-size: 22px;
  color: #1877F2;
}
.socials > *:nth-child(2){
  color: #1DA1F2;
}
.socials > *:nth-child(3){
  font-size: 22px;
  color: #c8232c;
}
.socials > *:nth-child(4){
  color: #dd4b39;
}

.socials > *:nth-child(1):hover {
   /* color: #1877F2; */
   transform: translateY(-6px);
   transition: all ease 500ms;
}

.socials > *:nth-child(2):hover {
  /* color: #1DA1F2; */
  transform: translateY(-6px);
  transition: all ease 500ms;
}

.socials > *:nth-child(3):hover {
  /* color: #c8232c; */
  transform: translateY(-6px);
  transition: all ease 500ms;
}

.socials > *:nth-child(4):hover {
  /* color: #dd4b39; */
  transform: translateY(-6px);
  transition: all ease 500ms;
}








/* .socials svg:hover {
  color: #00b3b3;
  transform: translateY(-6px);
} */

/* 
card */

/* @media (max-width: 1000px) {
  .managementCard {
    margin-bottom: 30px;
  }
} */
