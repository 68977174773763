.servicesCardTitle {
  font-size: 17px;
}

/* html {
    scroll-behavior: smooth;
  } */

.VideoGallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.VideoGallery__video {
  width: 300px;
  height: 200px;
  position: relative;
}

.VideoGallery__title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  margin: 0;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
}

.serviceCard{
  margin-bottom: 1rem;
  padding: 15px;
  /* box-shadow: -5px 6px 18px -10px rgba(0,0,0,0.67);
  -webkit-box-shadow: -5px 6px 18px -10px rgba(0,0,0,0.67);
  -moz-box-shadow: -5px 6px 18px -10px rgba(0,0,0,0.67); */
  box-shadow: -5px 6px 18px -10px rgba(29, 111, 218, 0.67);
  -webkit-box-shadow: -5px 6px 18px -10px rgba(29, 111, 218, 0.67);
  -moz-box-shadow: -5px 6px 18px -10px rgba(29, 111, 218, 0.67);
}
