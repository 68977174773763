@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');

body {
  background-color: #fff;
  padding: 0;
  margin: 0;
  /* cursor: all-scroll; */
}


