.backToTopBtn {
  position: fixed;
  bottom: 20px;
  right: 30px;
  /* color: #17a2b8;
  font-size: 25px; */
  border: none !important;
  background-color: none !important;
}

.backToTopBtn img{
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
}


/* .backToTopBtn svg {
  stroke: none !important;
}

.arrowIcon {
  stroke: none !important;
} */

@media (max-width: 1000px) {
  .backToTopBtn {
    right: 25px !important;
  }
}

@media (max-width: 768px) {
  .backToTopBtn {
    right: 20px !important;
  }
}
