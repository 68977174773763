.page-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .page-loader img{
    width: 180px;
    height: 120px;
  }


