/* .carousel-item-fixed-height{
    margin-top: 86px;
    width: 100%;
}

.carousel-item-fixed-height img {
    height: 350px;
    object-fit: cover; 
}

.carousel-item-fixed-height{
    margin-top: 50px;
    min-width: 100%;
} */
 
@media screen and (max-width: 1300px) {
   .carousel-item-fixed-height{
    margin-top: 5rem;
    height: 200px;
   }
   .carousel-item-fixed-height img{
    height: 200px;
   }
}
