/*     new css     */

 html:focus-within {
  scroll-behavior: smooth;
}
img,
picture {
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

body {
  position: relative;
  font-family: "Kumbh Sans", sans-serif;
}

.btnCart {
  /* width: 100px!important; */
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 1rem;
  border: none;
  border-radius: 0.75rem;
  background-color: #ff7d1a;
  color: #fff;
  box-shadow: 0 10px 10px 1px rgba(255, 125, 26, 0.4392156863);
}

@media(max-width:560px){
  .btnCart {
    margin-bottom: 3rem;
  }
  .tabBox{
   margin-bottom: 5rem;
  }
}

.btnCart:hover,
.btnCart:focus {
  background-color: #ff7d1a;
  opacity: 0.5;
}

.thumbnail {
  border-radius: 0.5rem;
  background-color: #fff;
  height: 90px;
}
.thumbnail > img {
  cursor: pointer;
  width: 92px;
  height: 100%;
}
.thumbnail > img > img:hover,
.thumbnail > img > img:focus {
  filter: opacity(0.5);
}
.thumbnail .active {
  filter: opacity(0.35);
  border: 1.5px solid #ff7d1a;
}

.flex {
  display: flex;
  gap: 1rem;
}

.flex2{
  display: flex;
  gap: 5px;
}

.grid {
  display: grid;
  gap: 1rem;
}

.flow > * + * {
  margin-top: 1.5rem;
}

.Orange {
  color: #ff7d1a;
}

.blue {
  color: #1d2025;
}

.darkGrayishBlue {
  color: #68707d;
}

.GrayishBlue {
  color: #b6bcc8;
}

.product {
  margin-top: 5.625rem;
}
.product .product-container {
  /* margin-left: 5rem; */
  grid-template-columns: repeat(2, 1fr);
  gap: 7.8125rem;
}
.product .product-container .product-image {
  flex-direction: column;
  gap: 2rem;
}
.product .product-container .product-image img {
  cursor: pointer;
  border-radius: 0.5rem;
}
.product .product-container .product-image .thumbnail-wrapper {
  align-items: center;
  gap: 2rem;
}
.product .product-container .product-description {
  padding-block: 3.875rem;
  /* margin-top: 1.5rem; */
}
.product .product-container .product-description .discounted-price {
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 1rem;
}
.product .product-container .product-description .discounted-price .offer {
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  background-color: #ff7d1a 0.25;
}
.product .product-container .product-description .action-wrapper {
  align-items: center;
  gap: 1rem;
  width: 180px;
}
.product
  .product-container
  .product-description
  .action-wrapper
  .product-quantity {
  gap: 3.5rem;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #f7f8fd;
}
.product
  .product-container
  .product-description
  .action-wrapper
  .product-quantity
  > img:hover,
  .product
  .product-container
  .product-description
  .action-wrapper
  .product-quantity
  > img:focus {
  cursor: pointer;
  filter: opacity(0.5);
}

.product-image{
  margin-bottom: 3rem;
}

@media (max-width: 64em) {
  .product {
    margin-top: 3rem;
  }
  .product .product-container {
    padding-inline: 2rem;
    gap: 1rem;
  }
  .product .product-container .product-image .thumbnail-wrapper {
    justify-content: center;
  }
  
  .product .product-container .product-description .action-wrapper {
    flex-direction: column;
    align-items: stretch;
  }

  .product
    .product-container
    .product-description
    .action-wrapper
    .product-quantity {
    justify-content: center;
  }
}
          
/* @media (max-width: 48em) { */
  @media (max-width: 35em) {
  .product {
    margin-top: 0;
  }
  .flow{
    margin-top: 0 !important;
  }
  .product .product-container .product-description {
    padding-block: 0;
  }
  .product .product-container {
    grid-template-columns: 1fr;
    padding: 0;
  }
  .product .product-container .product-image {
    position: relative;
  }
  .product .product-container .product-image img {
    cursor: none;
    border-radius: 0;
  }
  .product .product-container .product-image .lightbox-control {
    width: 40px;
  }
  .product .product-container .product-image .control-prev {
    top: 50%;
    left: 5%;
  }
  .product .product-container .product-image .control-next {
    top: 50%;
    right: 5%;
  }

    .product .product-container .product-image .thumbnail-wrapper {
      padding: 0 1rem;
    }
  
    .product .product-container .product-description {
      padding-inline: 1.5rem;
    }
  
    .product .product-container .product-description .product-price {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    }
  
    .productDescriptionImg img {
      height: 350px;
      padding: 20px;
      padding: 0 20px;
      width: 90%;
      margin: auto;
      background-color: #fff;
    }

    #imageMagnifier {
      background-color: #fff;
      /* background-color: red; */
      height: 500px;
      /* width: 500px; */
      border-radius: 5px;
    }
  
    #imageMagnifier img {
       height: 500px !important; 
    }

    /* Tg Logo */
    .productDetailsTitle {
      color: #4d4d4d;
      font-family: 'Montserrat', sans-serif;
      font-size: 20px;
      font-weight: 700;
    }

    .productDetailsList{
      list-style-type: circle;
    }

    @media(max-width:500px) {
      .productDetailsTitle {
        font-size: 18px;
        font-weight: 700;
      }
    }
  
    .tgLogo {
      width: 110px;
      height: 65px;
    }
  
    .custom-table {
      border: 0.5px solid #999999;
    }
  
    .singleItemProductTitle {
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
    }

    .large{
      background-color: #fff !important;
    }
  
    .singleItemProductValue {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 12px;
      text-transform: uppercase;
      line-height: 22px;
    }
  
    /* Related Products */
  
    .relatedProductInfo {
      padding: 1rem 6rem;
      background-color: #fff !important;
      height: 150px;
      color: #ff7d1a;
      margin: 2rem 0;
    }
  
    .relatedProductTitle {
      color: #000;
      font-size: 25px;
      font-weight: 700;
      margin-top: 1rem;
    }
  
    .relatedProductSubTitle {
      color: #808080;
      font-size: 18px;
      font-family: "Nunito", sans-serif;
      font-weight: 600;
    }

    .relatedBtn{
      font-size: 13px;
      padding: 5px;
      color: #fff;
      font-family: "Barlow",sans-serif;
      font-weight: 600;
      border: 1px solid #f2f2f2;
      width: 90px;
      height: 35px;
    }
  
    .relatedBtn:hover, .relatedBtn:focus {
      background-color: #cc5800;
      color: #fff; 
      border: 1px solid #f2f2f2;
    }
  
    /* Mui Tab Title (Description, Material-information, Warrenty) */
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  
    @media(max-width: 550px) {
      .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
        font-size: 12px !important;
      }
    }
  
    @media(max-width: 450px) {
      .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
        font-size: 10px !important;
      }
    }
  
    @media(max-width: 350px) {
      .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
        /* font-size: 10px !important; */
        padding: 0 !important;
        margin: 0 !important;
        font-size: 8px !important;
      }
    }
  
    @media (max-width:600px) {
      .relatedProductInfo {
        height: 150px;
      }
  
      .relatedProductTitle {
        font-size: 18px;
        font-weight: 600;
      }
  
      .relatedProductSubTitle {
        font-size: 14px;
        font-weight: 500;
      }
    }
  
    @media (max-width:450px) {
      .relatedProductInfo {
        padding: 1rem 2rem;
      }
    }
  
    @media (max-width: 300px) {
      .relatedProductInfo {
        height: 180px;
      }
    }

.related-portfolio-slider{
margin-right: 15px;
border-radius: 10px;
background-color: #fff;
height: 320px;
padding: 10px;
}

.related-portfolio-item {
  margin: 4rem 0;
}

.related-portfolio-item-img {
  border-radius: 5px;
}

.related-portfolio-item img {
  height: 220px;
  width: 280px;
  margin: auto;
  padding: 3px;
  /* border-radius: 10px; */
}

.productPrice{
  font-size: 20px;
  font-weight: 700;
  font-family: "Nunito",sans-serif;
}

.custom-slider button.slick-arrow.slick-next::before,
.custom-slider button.slick-arrow.slick-prev::before {
  color: #95a5a6 !important;
  background-color: #e6e6e6 !important;
}

.RelatedProductTable {
  width: 400px;
}

@media(max-width: 500px) {
  .RelatedProductTable {
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media(max-width: 400px) {
  .RelatedProductTable {
    width: 300px !important;
    padding: 0 !important;
  }
}

/* Product info tab */

.css-1aquho2-MuiTabs-indicator {
  background-color: #ff7d1a !important;
}

/* Store Location css */

.storeLocation{
  background-color: #fff;
  height: 250px;
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 2rem;
}

.leftStore{
  padding: 4rem;
}

.rightStore{
  padding: 4rem;
}

.storeLocationLogo{
  width: 30px;
  height: 40px;
  color: #ff7d1a;
  margin-bottom: 10px;
}

.storeHeader {
  color: #ff7d1a;
  font-size: 25px;
  font-weight: 700;
  margin-top: 10px;
}

.storeSubHeader{
  color: #595959;
  font-size: 15px;
}

.storeLocationLogoContact{
  color: #ff7d1a;
  margin-top: 2px;
  font-size: 25px;
  margin-bottom: 10px;
}

.Mui-selected{
   color: #ff7d1a !important;
}

@media(max-width:750px) {
  .storeLocation {
    margin-top: 1rem;
  }

  .leftStore {
    padding: 3rem;
  }

  .rightStore {
    padding: 3rem;
  }

  .storeLocationLogo {
    width: 25px;
    height: 35px;
  }

  .storeLocationLogoContact {
    color: #ff7d1a;
    margin-top: 2px;
    font-size: 20px;
  }

  .storeHeader {
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
  }

  .storeSubHeader {
    font-size: 12px;
  }
}

@media (max-width:500px) {
  .leftStore {
    padding: 2rem;
  }

  .rightStore {
    padding: 2rem;
  }

  .storeHeader {
    font-size: 15px;
  }

  .storeSubHeader {
    font-size: 11px;
  }
}

@media (max-width:400px) {
  .leftStore {
    padding: 1rem;
  }

  .rightStore {
    padding: 1rem;
  }

  .storeHeader {
    font-size: 12px;
  }
}

@media (max-width: 470px) {
  .RelatedProductTable {
    width: 360px;
    padding: 0;
  }

  .css-13xfq8m-MuiTabPanel-root {
    padding: 0 !important;
  }
}

@media (max-width: 350px) {
  .RelatedProductTable {
    width: 250px !important;
  }

  .css-13xfq8m-MuiTabPanel-root {
    padding: 0 !important;
  }
}

@media (max-width: 990px) {
  .singleItemProductValue {
    font-size: 10px !important;
    width: 600px !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .css-13xfq8m-MuiTabPanel-root {
    padding: 0 !important;
  }
}

/* Thumbnail img */
@media(max-width:1000px) {
  .thumbnail {
    height: 80px;
    padding: 0 !important;
  }
}

@media(max-width:700px) {
  .thumbnail {
    height: 70px
  }
}

  /* Product details main image */
    @media(max-width:1000px) {
      #imageMagnifier {
        height: 350px !important;
        width: 100%;
        padding: 0 !important;
        margin: 0 !important;
      }
  
      #imageMagnifier img {
        height: 350px !important;
        width: 100%;
      }
    }

    @media(max-width:768px) {
      #imageMagnifier {
        height: 300px !important;
      }
  
      #imageMagnifier img {
        height: 300px !important;
      }
    }

  
    @media(max-width:560px) {
      #imageMagnifier {
        padding: 0 5rem !important;
      }
  
      .tgLogo {
        display: none !important;
      }
  
      .product-image {
        margin-bottom: 1rem;
      }
    }

    @media(max-width:400px) {
      #imageMagnifier {
        /* padding: 0 5rem !important; */
        height: 260px !important;
      }
  
      #imageMagnifier img {
        height: 260px !important;
      }
    }

  /* Product description */
    @media(max-width:568px) {
      .product-description {
        margin-top: 0 !important;
      }
    }

/* Tab panel margin */
    @media(max-width:991px) {
      .tabPanelMargin {
        margin-top: 1rem !important;
      }
    }



    /* form css -> coustomer entry form */
    .form-container {
      display: flex;
      justify-content: center;
      padding: 20px;
    }
    
    .address-form {
      /* display: flex; */
      max-width: 600px;
      width: 100%;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #f9f9f9;
    }
    
    .address-form label {
      display: block;
      font-weight: bold;
    }
    
    .address-form input,
    .address-form textarea,
    .address-form select,
    .address-form button {
      width: 100%;
      padding: 8px;
      margin-top: 5px;
      margin-bottom: 15px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
    
    .address-form textarea {
      height: 100px;
    }
    
    .form-row {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
    
    .form-row label {
      flex: 1;
    }
    
    .address-form button {
      background-color: #007bff;
      color: white;
      border: none;
      cursor: pointer;
    }
    
    .address-form button:hover {
      background-color: #0056b3;
    }
    
    /* Responsive Design */
    @media (max-width: 768px) {
      .form-row {
        flex-direction: column;
      }
    }

    .productHeader {
      display: flex;
      justify-content: space-between; 
      align-items: center; 
      padding: 20px;
      background-color: #f8f8f8; 
    }
    
    .left {
      flex: 1; /* Adjust width of the left section */
      text-align: left;
    }
    
    .right {
      flex: 1; /* Adjust width of the right section */
      text-align: right;
    }