.ManagementCard {
  height: 280px;
  margin-bottom: 20px;
  text-align: center;
  box-shadow: -5px 6px 18px -10px rgba(29, 111, 218, 0.67);
  -webkit-box-shadow: -5px 6px 18px -10px rgba(29, 111, 218, 0.67);
  -moz-box-shadow: -5px 6px 18px -10px rgba(29, 111, 218, 0.67);
}

.ManagementCard img {
  margin: 5% 0 5% 0;
  height: 90%;
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 15px;
}

ul {
  margin-top: 0;
  margin-bottom: 20px;
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
}

.awards-section {
  max-width: 800px;
  margin: 0 auto;
}

.award {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: -5px 6px 18px -10px rgba(29, 111, 218, 0.67);
  -webkit-box-shadow: -5px 6px 18px -10px rgba(29, 111, 218, 0.67);
  -moz-box-shadow: -5px 6px 18px -10px rgba(29, 111, 218, 0.67);
}

.award h2 {
  margin-top: 0;
}

.award ul {
  margin-top: 10px;
}

.award li {
  margin-bottom: 5px;
}

.award img {
  max-width: 100%;
  margin-bottom: 10px;
}

.awardTitle {
  font-size: 30px;
}

.awardList{
  font-size: 15px;
  color: #595959;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.achievementCardImg {
  overflow: hidden;
}

.achievementCardImg {
  transition: transform 0.6s ease-in-out;
}

.achievementCardImg:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .awardTitle {
    font-size: 20px;
  }
  .awardList {
    font-size: 13px;
  }
}

@media only screen and (min-width: 350px) and (max-width: 768px) {
  .ManagementCard {
    height: 300px;
  }
}



  /* box-shadow: -5px 6px 18px -10px rgba(29, 111, 218, 0.67);
  -webkit-box-shadow: -5px 6px 18px -10px rgba(29, 111, 218, 0.67);
  -moz-box-shadow: -5px 6px 18px -10px rgba(29, 111, 218, 0.67); */