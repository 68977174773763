
/********** Template CSS **********/
:root {
    --primary: #00B98E;
    --secondary: #FF6922;
    --light: #EFFDF5;
    --dark: #0E2E50;
}

/*** Icon ***/
.icon {
    padding: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF !important;
    border-radius: 50px;
    border: 1px dashed var(--primary) !important;
}


/*** Category ***/
.cat-item div {
    background: #FFFFFF;
    border: 1px dashed rgba(0, 185, 142, .3);
    transition: .5s;
}

.cat-item:hover div {
    background: var(--primary);
    border-color: transparent;
}

.cat-item div * {
    transition: .5s;
}

.cat-item:hover div * {
    color: #FFFFFF !important;
}


/*** Property List ***/
.nav-pills .nav-item .btn {
    color: var(--dark);
}

.nav-pills .nav-item .btn:hover,
.nav-pills .nav-item .btn.active {
    color: #FFFFFF;
}

.property-item {
    box-shadow: 0 0 30px rgba(0, 0, 0, .08);
}

.property-item img {
    transition: .5s;
}

.property-item:hover img {
    transform: scale(1.1);
}

.property-item .border-top {
    border-top: 1px dashed rgba(0, 185, 142, .3) !important;
}

.property-item .border-end {
    border-right: 1px dashed rgba(0, 185, 142, .3) !important;
}

.propertyTempImg{
    height: 420px;
}


/* Property type */
.propertyHeader{
    color: #000;
    font-weight: 500;
}

.propertySpan{
    color: #00B98E;
    font-weight: 500;
}

.bg-light {
    background-color: #EFFDF5 !important;
}

/* .fadeInUp{
    background: #effdf5;
} */