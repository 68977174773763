.about-img img {
    position: relative;
    z-index: 2;
}

.about-img::before {
    position: absolute;
    content: "";
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    background: var(--primary);
    transform: skew(20deg);
    z-index: 1;
}

.TalukderDetailsTitle{
    margin-top: 5rem !important;
    font-size: 30px;
    font-weight: 700;
    color: #0E2E50;
}

@media(max-width:768px){
    .about-img img{
        height: 350px;
    }
}

@media(max-width:500px){
    .about-img img{
        height: 300px;
    }
}

@media(max-width:400px){
    .about-img img{
        height: 250px;
    }
}

@media(max-width:330px){
    .about-img img{
        height: 200px;
    }
}
